$(function () {
  //////アニメーション
  $(window).scroll(function () {
    $('.anime').each(function () {
      var position = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > position - windowHeight + 200) {
        $(this).addClass('anime_active');
      }
    });
  });
  //表示させるwidthを指定
  var ua = navigator.userAgent;
  var viewport = document.querySelector("meta[name=viewport]");
  if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    viewport.setAttribute("content", "width=device-width,initial-scale=1");
  } else {
    viewport.setAttribute("content", "width=1000");
  }
  //////画像切り替え
  var width = $(window).width();
  if (width < 768) {
    $("img").each(function () {
      $(this).attr("src", $(this).attr("src").replace("_pc", "_sp"));
    })
  }
  //////ページトップ
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
  //////スムーススクロール
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({
        scrollTop: position
      }, 500);
    }, 100);
  }
  $('a[href^="#"]').click(function () {
    var href = $(this).attr("href");
    var target = $(href);
    var position = target.offset().top - headerHeight;
    $('body,html').stop().animate({
      scrollTop: position
    }, 500);
    return false;
  });
  //////ナビゲーション
  $('.menu_trigger').on('click', function () {
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
    $('.header_contents').toggleClass('active');
  });
  var $win = $(window),
    animationClass = 'is_animation';
  $win.on('load scroll', function () {
    var value = $(this).scrollTop();
    if (value > 100) {
      $('.header').addClass('is_animation');
    } else {
      $('.header').removeClass('is_animation');
    }
  });
});
$('.header_nav_contents a').on('click', function () {
  if (window.innerWidth <= 768) {
    $('.menu_trigger').click();
  }
});
$(window).on('resize', function () {
  if ('none' == $('.header_nav_contents').css('display')) {
    $('.header_nav_contents').attr('style', '');
  }
});

$(function () {
  //////How to useスライダー
  $('.howto_slider').slick({
    arrows: true,
    prevArrow: '<button type="button" class="slide-arrow prev-arrow"><span class="slide-arrow__arrow prev-arrow__arrow"></span></button>',
    nextArrow: '<button type="button" class="slide-arrow next-arrow"><span class="slide-arrow__arrow next-arrow__arrow"></span></button>',
    centerMode: true,
    centerPadding: '10vw',
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 3,
    responsive: [{
        breakpoint: 1200, // 1200px以下のサイズに適用
        settings: {
          centerPadding: '2vw',
        },
      },
      {
        breakpoint: 1050, // 1050px以下のサイズに適用
        settings: {
          centerPadding: '30vw',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // 768px以下のサイズに適用
        settings: {
          centerPadding: '15vw',
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  });
  $('.case_slider').slick({
    arrows: true,
    prevArrow: '<button type="button" class="slide-arrow prev-arrow"><span class="slide-arrow__arrow prev-arrow__arrow"></span></button>',
    nextArrow: '<button type="button" class="slide-arrow next-arrow"><span class="slide-arrow__arrow next-arrow__arrow"></span></button>',
    centerMode: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    // autoplay: true,
    centerPadding: '5vw',
    slidesToShow: 3,
    responsive: [{
        breakpoint: 1050,
        settings: {
          centerPadding: '30vw',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // 768px以下のサイズに適用
        settings: {
          centerPadding: '15vw',
          slidesToShow: 1,
        },
      },
    ],
  });
});

//Faq
$('.faq_item_ttl').on('click', function () {
  $('.faq_item_txt').slideUp(500);
  var findElm = $(this).next(".faq_item_txt");
  if ($(this).hasClass('close')) {
    $(this).removeClass('close');
  } else {
    $('.close').removeClass('close');
    $(this).addClass('close');
    $(findElm).slideDown(500);
  }
});

//////追従ボタン
$(function () {
  var scrollStart = $('.dl_btn').offset().top; //ページ上部からの距離を取得
  var scrollEnd = $('footer').offset().top; //ページ上部からの距離を取得
  var distance = 0;

  $(document).scroll(function () {
    distance = $(this).scrollTop(); //スクロールした距離を取得

    if (scrollStart <= distance) { //スクロール距離が『.sikaku_box』の位置を超えたら
      $('.dl_btn').addClass('dl_btn_fixed'); //class『fixed』を追加
    } else if (scrollStart >= distance) { //スクロールがページ上部まで戻ったら
      $('.dl_btn').removeClass('dl_btn_fixed'); //class『fixed』を削除
    }

    if (scrollEnd <= distance) { //スクロール距離が『.end_box』の位置を超えたら
      $('.dl_btn').addClass('none'); //class『none』を追加
    } else {
      $('.dl_btn').removeClass('none'); //class『none』を削除
    }
  });
});